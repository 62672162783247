.error-message {
  display: block;
  margin-top: size(14);
  font-size: size(14);
  color: $ErrorColor;
  @at-root .Latin & {
    text-align: left;
  }
  @at-root .Arabic & {
    text-align: right;
  }
  @media screen and (max-width: $mobile1) {
    font-size: size(14);
    margin-top: size(12);
  }
}
.error-server {
  padding: 20px;
  margin-bottom: 15px;
  font-size: size(30);
  color: $ServerErrorTxtColor;
  background: $ServerErrorBgColor url('../img/subtract.svg') no-repeat;
  background-size: contain;
}

i.question-icn {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: size(30);
  width: size(30);
  height: size(20);
  background: none;
  // cursor: pointer;
  &:not(:disabled) {
    cursor: pointer;
  }

  @media screen and (max-width: $mobile1) {
    top: size(20);
  }
  svg {
    width: 30px;
    height: 20px;
  }
}

.radio {
  position: relative;
  i {
    display: block;
    width: size(15);
    height: size(15);
    background: #fff;
    border: 1px solid #9f9f9f;
    border-radius: 20px;
    position: absolute;
    top: 0;
    @at-root .Latin & {
      left: 0;
    }
    @at-root .Arabic & {
      right: 0;
    }
    // cursor: pointer;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  span {
    display: block;
    font-size: size(14);
    line-height: 1.1;
    @at-root .Latin & {
      margin-left: size(25);
    }
    @at-root .Arabic & {
      margin-right: size(25);
    }
  }
  input {
    visibility: hidden;
    position: absolute;

    &:checked + i {
      box-shadow: 0 0 size(2) $DarkBlue;
      border: size(1) solid #fff;
      background: $DarkBlue;
    }
  }
}

.checkbox {
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #9f9f9f;
    position: absolute;
    border-radius: 8px;
    // cursor: pointer;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  span {
    display: block;
    font-size: $font_paragraph_normal;
    line-height: 1.1;
    @at-root .Latin & {
      margin-left: 4rem;
    }
    @at-root .Arabic & {
      margin-right: 4rem;
    }
  }
  input {
    visibility: hidden;
    position: absolute;

    &:checked + i {
      border: 1px solid $DarkBlue;
      background-color: $DarkBlue;
    }
    &:checked + i:before {
      content: '';
      width: 10px;
      height: 8px;
      background: url(../../assets/img/tickWight.png) no-repeat;;
      background-size: contain;
      display: flex;
      justify-content: center;
    }
  }
}

.input-icon {
  & + i {
    width: 35px;
    height: 35px;
    top: -18px;
    left: 15px;
    @media screen and (max-width: $mobile1) {
      width: 30px;
      height: 30px;
    }
    svg {
      display: block;
      @media screen and (max-width: $mobile1) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  font-size: size(20);
}

select {
  -webkit-appearance: menulist-button;
}

.placeholder {
  width: 100%;
  color: $placeholderColor;
  font-size: size(14);
  position: absolute;
  top: 0;
  height: size(32);
  pointer-events: none;
  padding-top: size(10);
  @at-root .Latin & {
    left: 0;
  }
  @at-root .Arabic & {
    right: 0;
  }
}

.select-input {
  height: size(62);
}

.selected-option {
  color: $BaseTextColor;
}

.select-border {
  display: block;
  height: size(1);
  width: 100%;
  background: $inputBorder;
  position: absolute;
  bottom: 0;
}
