@import 'common_files';

$doc_size: 10;

.main-fallback {
  min-height: 80vh;
  & > .fallback {
    min-height: 80vh;

    .Loader {
      transform: translateY(40vh);
      margin: -5rem auto 0;
    }

    .LoaderWrapper {
      min-height: 80vh;
    }
  }
}

.mainWrapper {
  min-height: 80vh;
  padding-top: 88px;

  & > .LoaderWrapper {
    min-height: 80vh;

    .Loader {
      transform: translateY(40vh);
      margin: -5rem auto 0;
    }
  }

  @at-root .Arabic & {
    direction: rtl;
  }
}

html {
  font-size: 62.5%;

  @media screen and (max-width: 1440px) {
    font-size: 9px;
    $doc_size: 9;
  }

  @media screen and (max-width: 1360px) {
    font-size: 8px;
    $doc_size: 8;
  }

  @media screen and (max-width: 1150px) {
    font-size: 7px;
    $doc_size: 7;
  }

  @media screen and (max-width: $mobile1) {
    font-size: 10px;
    $doc_size: 10;
  }

  @media screen and (max-width: $mobile2) {
    font-size: 9px;
    $doc_size: 9;
  }
}

@function size($size) {
  $rem: $size / $doc_size;
  @return #{decimal-round($rem, 5, ceil)}rem;
}

body {
  line-height: 1;
  color: $BaseTextColor;
}

.box {
  box-sizing: border-box;
  box-shadow: size(3) size(8) size(20) rgba(0, 0, 0, 0.15);
  border-radius: size(25);
  background-color: #fff;
}

.box-title {
  font-size: size(16);
  padding: size(20);
  margin: 0;
  color: #fff;
}

.clearfix {
  @extend %clearfix;
}

.fixed {
  position: fixed;
}

.container {
  max-width: size(1440);
  width: 100%;
  margin: 0 auto;
  @extend %regular;

  @at-root .Latin & {
    direction: ltr;
  }

  @at-root .Arabic & {
    direction: rtl;
  }

  @media screen and (max-width: $tabletLandscape) {
    padding: 0 21px;
    box-sizing: border-box;
  }
}
.static-page-container {
  max-width: size(1240);
  width: 100%;
  margin: 0 auto;
  @extend %regular;

  @at-root .Latin & {
    direction: ltr;
  }

  @at-root .Arabic & {
    direction: rtl;
  }

  @media screen and (max-width: $tabletLandscape) {
    padding: 0 21px;
    box-sizing: border-box;
  }
}

a:link,
a:hover {
  text-decoration: none;
}

label {
  @extend %regular;
  // cursor: pointer;
  &:not(:disabled) {
    cursor: pointer;
  }
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholderColor;
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholderColor;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea::-webkit-input-placeholder {
  color: $placeholderColor;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholderColor;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholderColor;
}

textarea:-ms-input-placeholder {
  color: $placeholderColor;
}

textarea::placeholder {
  color: $placeholderColor;
}


.relative {
  position: relative;
}

.center {
  text-align: center;
}

.margB-30 {
  margin-bottom: size(30);
}

.margb-25 {
  margin-bottom: size(25);
}

.margb-21 {
  margin-bottom: size(21);
}

.margb-17 {
  margin-bottom: size(17);
}

.overflow {
  overflow: hidden;
}

.arr {
  width: 0.9rem;
  height: 0.5rem;
  background: url(../../assets/img/arrowDown.svg) no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: -0.2rem;
}

strong {
  @extend %bold;
}

.upper-section-about-us {
  color: $DarkTextColor;
  padding: size(100) 0;
  box-sizing: border-box;

  @media screen and (max-width: $mobile1) {
    padding-top: size(34);
  }

  .container {
    @media screen and (max-width: $mobile1) {
      padding: 0;
    }
  }

}

.intro {
  text-align: center;

  &-title {
    font-size: size(36);
    color: $UpperSectionIntroTitleTxtColor;

    @media screen and (max-width: $mobile1) {
      font-size: size(20);
    }
  }

  &-desc {
    font-size: size(24);

    @media screen and (max-width: $mobile1) {
      font-size: size(18);
    }
  }
}

.inline-number {
  display: inline-block;
  direction: ltr;
}

h1 {
  @extend %bold;
  font-size: size(48);

  @media screen and (max-width: $mobile1) {
    font-size: size(24);
  }
}

h2 {
  margin: 0;
  font-size: size(26);
  color: $headerTxtColor;

  @media screen and (max-width: $mobile1) {
    font-size: size(18);
  }
}

h3 {
  color: $headerTxtColor;
  font-size: size(24);
}

p {
  font-size: size(18);
}

ul {
  li {
    font-size: $font_paragraph_small;
  }
}

button,
a {
  // cursor: pointer;
  &:not(:disabled) {
    cursor: pointer;
  }
}
button {
  font-family: Bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
  @at-root .Arabic & {
    text-align: right;
  }
}

.clear-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  @extend %bold;
  font-size: size(36);

  @media screen and (max-width: $mobile1) {
    font-size: size(18);
  }
}

.dark-bg {
  background: $DarkBG;

  .title {
    color: $DarkTextBg;
  }
}

a:visited {
  @extend a;
}

html,
body {
  height: 100%;
  width: 100%;
}

.Latin,
.Arabic {
  font-size: size(14);
}

.page {
  padding-top: size(140);

  @media screen and (max-width: $mobile414) {
    padding-top: size(50);
  }
}

.clearfix:after,
.clearfix:before {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.table-style-1 {
  width: 100%;
  border-spacing: 0;
  text-align: center;

  thead {
    display: table-header-group;
    background: $tableHeaderBgColor;

    tr {
      th {
        padding: size(23) size(18);
      }
    }
  }

  tr {
    td {
      border-bottom: 1px solid $tableInBetweenBorderColor;

      @media screen and (max-width: 600px) {
        text-align: right;
        border-bottom: 1px solid #ddd;
      }

      &:first-child {
        @media screen and (max-width: 600px) {
          background: #01a6eb;
          color: #fff;
        }
      }
    }

    &:last-child td {
      border-bottom: none;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    @media screen and (max-width: 600px) {
      border-bottom: 1px solid #ddd;
      display: block;
      background: #eee;
      margin-bottom: 30px;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  th {
    @extend %bold;
    font-size: size(20);
    color: $tableHeaderColor;

    &:first-child {
      @at-root .Latin & {
        border-radius: 10px 0 0 0;
      }

      @at-root .Arabic & {
        border-radius: 0 10px 0 0;
      }
    }

    &:last-child {
      @at-root .Latin & {
        border-radius: 0 10px 0 0;
      }

      @at-root .Arabic & {
        border-radius: 10px 0 0 0;
      }
    }

    @media screen and (max-width: $mobile1) {
      font-size: size(16);
    }
  }

  td {
    @extend %bold;
    font-size: size(18);
    padding: size(20);
    margin-right: 0%;

    @media screen and (max-width: $mobile1) {
      font-size: size(14);
    }
  }
}

.inline-list li {
  display: inline-block;
}

.container-wrapper {
  @media screen and (min-width: $desktop-min) and (max-width: 1200px) {
    padding: 0 size(30);
  }
}

.no-data-found {
  display: flex;
  justify-content: center;
  padding: 5rem;
}

a.packageCardLink {
  color: $BaseThemeColor;
  font-size: size(14);
}

.numberLtr {
  display: inline-block;
  direction: ltr;
}

a.themedLink {
  color: $BaseThemeColor;
}

.hawakomContainer {
  max-width: 1000px;
  margin: size(80) auto;
  line-height: 1.5;

  a:link,
  a:visited {
    color: $BaseThemeColor;
  }

  @media screen and (max-width: 767px) {
    p {
      font-size: size(14);
    }
  }
}

.minLayoutHeight {
  min-height: 730px !important;
}

.lebara-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Bold;
  font-size: $font_paragraph_normal;
  border-radius: 100px;
  color: $ButtonColor;
  background-color: transparent;
  border: 1px solid $ButtonColor;
  padding: 15px 24px;
  line-height: 20px;
  &:hover {
    text-decoration: none;
    color: $BaseLinkColor;
    border-color: $BaseLinkColor
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  img {
    height: 20px;
  }
  &.dark {
    color: $LightBlue;
    border-color: $LightBlue;
    &:hover {
      color: #E6F6FD;
      border-color: #E6F6FD;
    }
  }
  &.primary {
    color: $LightTextColor;
    background-color: $PrimaryButtonBackground !important;
    border-color: $PrimaryButtonBackground;

    &:not(:disabled):hover {
      background-color: $PrimaryButtonHover !important;
      border-color: $PrimaryButtonHover;
    }
   
    &:focus {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &.yellow {
    color: $LightTextColor;
    background-color: $YellowColor;
    border-color: $YellowColor;

    &:hover {
      background-color: $YellowColorHover;
      border-color: $YellowColorHover;
    }

    &:focus {
      opacity: 0.75;
    }
  }

  &.wide {
    width: 100%;
    max-width: 400px;
    justify-content: center;
  }

  &.small {
    font-size: 14px;
    padding: 8px 16px;
    height: 38px;
  }
}
.button-link {
  display: flex;
  align-items: center;
  font-size: $font_paragraph_normal;
  color: $DarkTextColor;
  padding: 16px 12px;
  font-family: Bold;
  border-radius: 8px;
  background-color: white;
  margin: 6px 0;
  width: 100%;
  box-shadow: 0px 1px 6px 0px #1a1f4533;

  img {
    margin-right: 8px;
    width: 43px;
    height: 43px;

    @at-root .Arabic & {
      margin-left: 8px;
      margin-right: 0;
    }
  }
}
.button-link-primary {
  max-width: fit-content;
  border-radius: 100px;
  background-color: $PrimaryButtonBackground;
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-weight: 700;
  font-family: bold;

  &:hover {
    background-color: $PrimaryButtonHover;
    color: #fff;
    span {
      color: #fff;
    }
  }
  span {
    font-size: size(14);
    font-weight: 700;
    font-family: bold;
  }
}

.mobile-only {
  @media screen and (min-width: $mobile550) {
    display: none !important;
  }
}

.mobile-tablet-only {
  @media screen and (min-width: $desktop-min) {
    display: none !important;
  }
}

.tablet-desktop-only {
  @media screen and (max-width: $mobile550) {
    display: none !important;
  }
}

.desktop-only {
  @media screen and (max-width: $desktop-min-1) {
    display: none !important;
  }
}

.w-100 {
  width: 100%;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  &.space-around {
    justify-content: space-around;
  }
}

.bold {
  font-family: Bold;
}
.full-height-desktop {
  @media screen and (min-width: $desktop-min) {
    height: 100%;
  }
}
