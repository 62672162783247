@import './mixins';

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}


.bold{
  @extend %bold;
}

.Latin {
  @extend %regular;
  line-height: 1;
}

.Arabic {
  @extend %regular;
  line-height: 1.15;
}

$desktop_font_xlarge: 78.4px;
$desktop_font_large: 54.4px;
$desktop_font_normal: 46.4px;
$desktop_font_small: 34.4px;

$mobile_font_xlarge: 48px;
$mobile_font_large: 40px;
$mobile_font_normal: 35.2px;
$mobile_font_small: 24.2px;

$font_paragraph_xlarge: 31.6px;
$font_paragraph_large: 22.4px;
$font_paragraph_normal: 16px;
$font_paragraph_small: 13.1px;
$font_paragraph_overline: 11.2px;


