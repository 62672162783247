.payment-page-recharge-form {
    .wpwl-control {
      height: 3.125em !important;
    }
    .wpwl-form {
      margin: auto !important;
      max-width: initial !important;
    }
  
    .payment-recharge-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: size(18);
    }
    .pay-logo-card-recharge {
      max-width: size(280);
    }
    .wpwl-brand {
      height: 40px !important;
    }
    .wpwl-label {
      text-transform: capitalize;
    }
    .wpwl-control-cardNumber {
      input {
        background-color: red !important;
      }
    }
  }