@import './assets/scss/fonts';
@import './assets/scss/normalize';
@import './assets/scss/common';
@import './assets/scss/payment';
@import './assets/scss/form';

html{
  scroll-behavior: smooth;
}

.borderBox {
  border: 1px solid #e6e6e6;
  border-radius: size(10);
}
html{
  scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.smlTxt {
  font-size: size(10);
  line-height: size(13);
  color: #9f9f9f;
}

.link_effect {
  &:hover {
    text-decoration: underline;
  }
}

